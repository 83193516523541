/* Styles pour la zone qui contient le logo*/
.LogoBox{
    display: flex;
    flex-direction: column;
    background-color: #fce9e1;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 1;
    overflow-x: hidden;
        
    img {
        width: 80%;
        height: fit-content;
    }

    p {
        font-size: 2vw;
    }
}

.LoginBox{
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    
    p{
        text-align: center;
    }
    /* Styles pour la zone qui contient le formulaire de connexion*/
    .login-form {
        width: 80%;
        max-width: 500px;
    }
    h1{
        text-align: center;
        font-size: 40px;
    }
    
    .form-errors{
        color: red;
        padding: 8px;
    }
    .form-username, .form-password {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    
          .form-label{
              text-align: left;
              font-weight: bold;
              font-size: 16px;
              margin-bottom: 5px;
          }
    }
    
    .form-button {
        margin-top: 35px;
    }
}


#error-message-login {
  margin-top: 1em;
}

#password::-ms-clear, ::-ms-reveal {
    display: none;
}