#wrapper-list {
  height: 100%;
}

.inputs-container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  text-align: left;

  .input-edit {
    width: 50%;
  }
  .input-label {
    font-weight: lighter;
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.form-errors {
  color: red;
  padding: 8px;
}

.box {
  display: flex;
  flex-direction: row;
}

.box-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50%;
}

.error-text {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  width: 50%;
  padding-left: 50%;
}
