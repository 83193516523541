#qr-code {
    img, canvas {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    #logo {
        top: 165px;
        z-index: 1;
    }
}
#qr-code-container {
    margin-top: 25px;
}

#print-btn {
    position: absolute;
    width: 400px;

    left: 50%;
    transform: translateX(-50%);

    top: 430px;
}

#download-btn {
    position: absolute;
    width: 400px;

    left: 50%;
    transform: translateX(-50%);

    top: 465px;
}

@media print {
    .no-print {
        visibility: hidden;
    }
}