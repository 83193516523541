.MuiButton-root{
  background-color: #ed6b39 !important;
  width: fit-content;
  text-transform:none;  
  & :hover{
    background-color: #bc4c20;
  }
  &:disabled{
    color: #777;
    box-shadow: none;
  }
}

.MuiTypography-root{
  font-weight: bold;
  text-align: center;
  color:#4B4B4B;
}
.MuiTypography-h1{
  text-align: center;
  font-size: 2.5rem !important;
  font-weight: bold;
  color: #4d4d4d;
}
.MuiTypography-h2{
  &{
    font-size: 1.5rem !important;
    text-align: center;
    font-weight: bold;
    color: #4d4d4d;
  }
}
.MuiTypography-h3{
  &{
    font-size: 1rem !important;
    text-align: center;
    font-weight: bolder !important;
    color: #4d4d4d;
  }
}
.MuiTypography-h4{
  &{
    font-size: 1rem !important;
    text-align: left;
    font-weight: bold;
    color: #4d4d4d;
  }
}
.MuiTypography-h5{
  &{
    font-size: 0.6rem !important;
    text-align: center;
    font-weight: bold;
    color: #4d4d4d;
  }
}
.MuiTypography-subtitle1{
  text-align: left;
  font-weight: bold;
  font-size: 1.3rem !important;
  color: #333;
  margin-top: 2.5rem !important;
}

.MuiTypography-body1{
  text-align: left;
  margin-left: 1rem !important;
  font-size: 1rem !important;
  color: #444;
}
.MuiTypography-body2{
  text-align: left;
  font-size: 1rem !important;
  color: #444;
}

.MuiTextField-root{
  margin: auto;
  width: 100%;
  & .MuiOutlinedInput-root{
    &:hover fieldset{
      border-color: #ed6b39;
    }
    &.Mui-focused fieldset{
      border-color: #ed6b39;
    }
  }
}

.orangeField > fieldset{
  border-color: #ed6b39;
}

.MuiFormControl-root{
  margin: auto;
  width: 100%;
  & .MuiOutlinedInput-root{
    &:hover fieldset{
      border-color: #ed6b39;
    }
    &.Mui-focused fieldset{
      border-color: #ed6b39;
    }
  }
}

.MuiContainer-root{
  background-color: #FFF;
}
.container-input{
  margin-top: 2rem;
}

.form-errors {
  color: red;
  padding: 8px;
}

.app {
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .app-footer {
    margin-top: auto;
    width: 100%;
    z-index: 10;
  }

  .footer-padding {
    padding-top: 3rem;
  }
  .hide{
    display: none;
  }
}

.flag {
  &.red {
      color: red;
  }

  &.green {
      color: green;
  }
}

.form-group {
  max-width: 600px;

  .inputs-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    margin-top: 25px;

    .input-label {
      flex-direction: row;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 5px;
    }
  }
  
  .password-message {
    margin-top: 25px;
  }
}

.page-background {
  background-color: #F2F2F2;
}

.info-link {
  color: #4d4d4d;
  align-self: center;
}
.info-link:hover {
  color: #bc4c20;
  align-self: center;
}

// En haut c'est sur qu'on le garde
p,h1,h2,span {
  font-family: Georgia, Times, Times New Roman, serif;
  color: #4d4d4d;
}

.list {
  height: 48em;
}

.list-rows {
  height: 100%;
  width: 100%;
}

.longName {
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: rgba(0, 0, 0, 0.87);
}
