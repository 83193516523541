.logoNav {
  width: fit-content;
  height: 6em;
}

#navbar {
  background-color: #eee;
  min-height: 5em;
  margin-bottom: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
}

header.none {
  display: none;
}
