.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
  background-color: #4b4b4b;
  font-family: Georgia, "Times New Roman", Times, serif;
  text-align: center;
  span {
    color: #d3d3d3;
  }
}
